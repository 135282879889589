
import { defineComponent } from "vue";
import { client } from '@/client';
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { UserData } from "@/shared/types/UserData";
import { FriendsData, FriendsStatus } from "@/shared/types/FriendsData";
export default defineComponent({
    name: "friend-add",
    setup() {
      return {
        Search,
      };
    },
  
    computed: {
      user() {
        return this.params.user?this.params.user:{} as UserData;
      },
      friend() {
        return this.params.friend?this.params.friend:{} as UserData;
      },
    },
    data: () => {
      return {
        params: {} as FriendsData,
        roles: [
            {label:"全部",value:'0'},
            {label:"黑名单",value:FriendsStatus.applying},
            {label:"我的好友",value:FriendsStatus.agree},
            {label:"亲密好友",value:FriendsStatus.rejected}
        ],
        roleSelected:['0'] as any[],

        groups: [] as {label:string,value:string}[],
        groupSelected:['0'] as any[],
  
        paramsRules: {
        },
      };
    },
    async created() {
      let id = this.$route.params.id as string;
      this.params.userId = parseInt(id);
      this.groupList();
      this.groupSelected[0];
    },
    methods: {
        handleChange(){
            this.params.status = this.roleSelected[0];
            console.error(this.roleSelected[0]);
        },
      //新增
      async create() {
        let groupid = parseInt(this.groupSelected[0])
        if (groupid && groupid != 0) {
            this.params.groupId = groupid;
        }
        let res = await client.callApi("friends/Add",{
            ...this.params,
            friendId:parseInt(this.params.friendId.toString())
        });
        if (res.isSucc) {
            ElMessage({
                message: "更新成功^_^",
                type: "success",
            });
            this.$router.go(-1);
        }else{
            ElMessage({
                message: res.err.message,
                type: "error",
            });
        }
      },
    //查询栏目
    async groupList() {
        this.groups = [{label:"未分组",value:"0"}];
        let res = await client.callApi("group/Index",{userid:this.params.userId});
        if (res.isSucc) {
          let data = res.res.list;
          for (const item of data) {
            this.groups.push({
              label:item.groupName,
              value:item.id.toString()
            })
          }
        }
    },
      submit(formName: string) {
        let refs:any = this.$refs[formName];
  
        refs.validate((valid: any) => {
          if (valid) {
              this.create();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
  });
  